<template>
	<Component :is="computedName" class="c-page-icon" />
</template>

<script>
export default {
	name: 'PageIcon',

	components: {
		SvgBuilding: () =>
			import('~/assets/svgs/page-icons/building.svg?inline'),
		SvgBus: () => import('~/assets/svgs/page-icons/bus.svg?inline'),
		SvgCard: () => import('~/assets/svgs/page-icons/card.svg?inline'),
		SvgCase: () => import('~/assets/svgs/page-icons/case.svg?inline'),
		SvgChat: () => import('~/assets/svgs/page-icons/chat.svg?inline'),
		SvgChild: () => import('~/assets/svgs/page-icons/child.svg?inline'),
		SvgCutlery: () => import('~/assets/svgs/page-icons/cutlery.svg?inline'),
		SvgFactory: () => import('~/assets/svgs/page-icons/factory.svg?inline'),
		SvgFile: () => import('~/assets/svgs/page-icons/file.svg?inline'),
		SvgGameController: () =>
			import('~/assets/svgs/page-icons/game-controller.svg?inline'),
		SvgHeart: () => import('~/assets/svgs/page-icons/heart.svg?inline'),
		SvgHeartbeat: () =>
			import('~/assets/svgs/page-icons/heartbeat.svg?inline'),
		SvgHome: () => import('~/assets/svgs/page-icons/home.svg?inline'),
		SvgPackage: () => import('~/assets/svgs/page-icons/package.svg?inline'),
		SvgRecycle: () => import('~/assets/svgs/page-icons/recycle.svg?inline'),
		SvgRocket: () => import('~/assets/svgs/page-icons/rocket.svg?inline'),
		SvgSchool: () => import('~/assets/svgs/page-icons/school.svg?inline'),
		SvgTennisBall: () =>
			import('~/assets/svgs/page-icons/tennis-ball.svg?inline'),
		SvgTruck: () => import('~/assets/svgs/page-icons/truck.svg?inline'),
		SvgWater: () => import('~/assets/svgs/page-icons/water.svg?inline'),
		SvgPersonSimpleRunFill: () =>
			import(
				'~/assets/svgs/page-icons/person-simple-run-fill.svg?inline'
			),
	},

	props: {
		name: {
			type: String,
			default: '',
			validator(value) {
				return [
					'',

					'building.svg',
					'bus.svg',
					'card.svg',
					'case.svg',
					'chat.svg',
					'child.svg',
					'cutlery.svg',
					'factory.svg',
					'file.svg',
					'game-controller.svg',
					'heart.svg',
					'heartbeat.svg',
					'home.svg',
					'package.svg',
					'recycle.svg',
					'rocket.svg',
					'school.svg',
					'tennis-ball.svg',
					'truck.svg',
					'water.svg',
					'person-simple-run-fill.svg',
				].includes(value);
			},
		},
	},

	computed: {
		computedName() {
			if (!this.name) {
				return null;
			}

			return (
				'Svg' +
				this.name
					.replace('.svg', '')
					.replace(/^(.)|-(.)/g, (...args) => {
						return (args[1] || args[2]).toUpperCase();
					})
			);
		},
	},
};
</script>
