<template>
	<div
		id="navigation-dropdown"
		:class="[
			'c-navigation-header-dropdown-desktop',
			'fixed top-0 left-0 w-full h-screen',
		]"
		aria-label="Navigationsmenu"
		role="region"
	>
		<div
			:class="[
				'c-navigation-header-dropdown-desktop__backdrop',
				'w-full h-full bg-primary-button',
			]"
			@click="closeDropdown"
		></div>

		<div
			:class="[
				'c-navigation-header-dropdown-desktop__content',
				'absolute top-0 left-0',
				'w-full bg-primary-medium',
				'pb-lg',
			]"
		>
			<div class="w-full px-layout-margin max-w-layout-max mx-auto">
				<div
					ref="contentContainer"
					:class="[
						'c-navigation-header-dropdown-desktop__content-container',
						'relative duration-300 ease-smooth-out pl-2col pr-1col',
					]"
				>
					<TransitionExt
						appear
						name="navigation-dropdown__inner"
						:duration="300"
						@enter="onContentEnter"
					>
						<div
							:key="item.id"
							ref="content"
							:class="[
								'c-navigation-header-dropdown-desktop__content-item',
								'grid grid-cols-3 gap-x-layout-gutter gap-y-md',
								'absolute px-layout-gutter',
							]"
						>
							<div
								v-for="(child, index) in item.children"
								:key="`dropdown-${index}`"
							>
								<NuxtLinkExt
									:to="child.url"
									:target="child.target"
									class="inline-flex flex-col"
								>
									<div
										class="
											text-h3
											font-semibold
											inline-flex
											items-baseline
										"
									>
										<!-- Icon space -->
										<div
											class="
												inline-flex
												justify-center
												items-center
												flex-shrink-0
												-ml-8
												pl-8
												w-md
												mr-sm
											"
											style="height: 0"
										>
											<PageIcon
												v-if="child.icon"
												:name="child.icon"
												class="
													w-full
													transform
													translate-y-1/4
												"
												style="aspect-ratio: 1"
											/>
										</div>

										<!-- Text space -->
										{{ child.title }}
									</div>
									<div
										v-if="child.teaser"
										class="inline-flex flex-shrink-0 mt-10"
									>
										<!-- Icon space (just space) -->
										<div
											class="
												flex-shrink-0
												-ml-8
												pl-8
												w-md
												mr-sm
											"
										></div>

										<!-- Text space -->
										<!-- REMOVED BY STO 2022-06-17 -->
										<!-- {{ child.teaser }} -->
									</div>
								</NuxtLinkExt>
							</div>
						</div>
					</TransitionExt>
				</div>
			</div>

			<BaseButton
				class="mt-lg mx-auto"
				@click="closeDropdown"
				v-text="'Luk'"
			>
				<template #icon><SvgCross /></template>
				<template #default>Luk</template>
			</BaseButton>
		</div>
	</div>
</template>

<script>
import PageIcon from '~/components/shared/PageIcon.vue';
import SvgCross from '~/assets/svgs/cross-icon.svg?inline';

export default {
	name: 'NavigationHeaderDropdownDesktop',
	components: { PageIcon, SvgCross },

	props: {
		item: {
			type: Object,
			required: true,
		},
	},

	methods: {
		closeDropdown() {
			this.$emit('close');
		},
		onContentEnter() {
			if (this.$refs?.content && this.$refs.contentContainer) {
				const { content, contentContainer } = this.$refs;
				const { height } = content.getBoundingClientRect();
				contentContainer.style.height = `${height}px`;
			}
		},
	},
};
</script>

<style lang="postcss">
.c-navigation-header-dropdown-desktop__content {
	padding-top: 12.3vw;
	padding-top: min(12.3vw, 236px);
}

.c-navigation-header-dropdown-desktop__content-container {
	padding-right: calc(100% / 12);
	padding-left: calc(100% / 12 * 2);
}

.c-navigation-header-dropdown-desktop__content-container
	.c-navigation-header-dropdown-desktop__content-item {
	width: calc(100% - (100% / 12 * 3));
}

.t-navigation-dropdown-desktop-enter-active > *,
.t-navigation-dropdown-desktop-leave-active > * {
	@apply duration-500 ease-smooth-out transform;
}

.t-navigation-dropdown-desktop-enter
	.c-navigation-header-dropdown-desktop__backdrop,
.t-navigation-dropdown-desktop-leave-to
	.c-navigation-header-dropdown-desktop__backdrop {
	@apply opacity-0;
}

.t-navigation-dropdown-desktop-enter
	.c-navigation-header-dropdown-desktop__content,
.t-navigation-dropdown-desktop-leave-to
	.c-navigation-header-dropdown-desktop__content {
	@apply -translate-y-full;
}

.t-navigation-dropdown__inner-enter-active,
.t-navigation-dropdown__inner-leave-active {
	@apply duration-300 ease-smooth-out transform;
}

.t-navigation-dropdown__inner-enter {
	@apply opacity-0;
}

.t-navigation-dropdown__inner-leave-to {
	@apply opacity-0;
}
</style>
