<template>
	<div
		id="navigation-dropdown"
		:class="[
			'c-navigation-header-dropdown-mobile',
			'fixed top-0 left-0 w-full h-screen',
		]"
		aria-label="Navigationsmenu"
		role="region"
	>
		<div
			:class="[
				'c-navigation-header-dropdown-mobile__backdrop',
				'w-full h-full bg-primary-button',
			]"
			@click="closeDropdown"
		></div>

		<div
			:class="[
				'c-navigation-header-dropdown-mobile__content',
				'absolute top-0 left-0',
				'w-full max-h-visual-screen overflow-y-auto',
				'px-layout-margin pb-lg bg-primary-medium w-full',
			]"
		>
			<AccordionGroup :max-one-open="true">
				<div
					v-for="(item, index) in items"
					:key="`item-${index}`"
					class="border-b border-primary-light"
				>
					<NuxtLinkExt
						v-if="!item.isMegaMenu"
						:to="item.url"
						:target="item.target"
						class="block w-full py-sm uppercase"
					>
						<div
							class="text-h5 font-semibold"
							v-text="item.title"
						></div>

						<div
							class="text-h6 font-normal"
							v-text="item.teaser"
						></div>
					</NuxtLinkExt>

					<AccordionHeader
						v-if="item.isMegaMenu"
						:id="`header-${_uid}-${index}`"
						:key="`header-${_uid}-${index}`"
						:aria-controls="`panel-${_uid}-${index}`"
						class="w-full py-sm flex justify-between"
					>
						<div class="uppercase">
							<div
								class="text-h5 font-semibold"
								v-text="item.title"
							></div>

							<div
								class="text-h6 font-normal"
								v-text="item.teaser"
							></div>
						</div>

						<SvgCaret
							:class="[
								'flex-shrink-0 text-text w-10',
								'transform duration-300 ease-smooth-out',

								{
									'rotate-90': !panelsOpen[index],
									'-rotate-90': panelsOpen[index],
								},
							]"
						/>
					</AccordionHeader>

					<AccordionPanel
						v-if="item.isMegaMenu"
						:id="`panel-${_uid}-${index}`"
						:key="`panel-${_uid}-${index}`"
						:transition="{
							name: 't-navigation-dropdown-mobile-item',
						}"
						@change="(event) => onPanelChange(event, index)"
					>
						<div class="px-sm pb-md pt-8 space-y-sm">
							<NuxtLinkExt
								v-for="(child, cindex) in item.children"
								:key="`child-${cindex}`"
								:to="child.url"
								:target="child.target"
								class="flex items-center"
							>
								<div
									v-if="child.icon"
									class="flex-shrink-0 w-md mr-sm"
								>
									<PageIcon
										:name="child.icon"
										class="w-md text-text"
									/>
								</div>

								<div
									class="flex-1 text-h3 font-semibold"
									v-text="child.title"
								></div>
							</NuxtLinkExt>
						</div>
					</AccordionPanel>
				</div>
			</AccordionGroup>

			<NuxtLinkExt
				v-if="supportPage"
				class="block w-fit mx-auto mt-xl"
				:target="supportPage.target"
				:to="supportPage.url"
			>
				<BaseButton tag="div" v-text="'Kontakt'" />
			</NuxtLinkExt>

			<NuxtLinkExt
				v-if="contact"
				target="_self"
				:to="contact.url"
				:aria-label="contact.name"
				:class="[
					'w-fit h-40 rounded-full bg-primary-button text-white mx-auto mt-xl',
					'flex justify-center items-center px-16 uppercase font-semibold',
				]"
			>
				{{ contact.name }}
			</NuxtLinkExt>
		</div>
	</div>
</template>

<script>
import {
	AccordionGroup,
	AccordionHeader,
	AccordionPanel,
} from '~/citi-baseline/components/Accordion';
import PageIcon from '~/components/shared/PageIcon';
import SvgCaret from '~/assets/svgs/caret-icon.svg?inline';

export default {
	name: 'NavigationHeaderDropdownMobile',

	components: {
		AccordionGroup,
		AccordionHeader,
		AccordionPanel,
		PageIcon,
		SvgCaret,
	},

	props: {
		items: {
			type: Array,
			required: true,
		},
		contact: {
			type: Object,
			required: true,
		},
	},

	data() {
		return {
			panelsOpen: this.items.map(() => false),
			supportPage: this.$store.state.site?.supportPage,
		};
	},

	mounted() {
		document.body.firstElementChild.style.overflow = 'hidden';
	},

	beforeDestroy() {
		document.body.firstElementChild.style.overflow = null;
	},

	methods: {
		closeDropdown() {
			this.$emit('close');
		},
		onPanelChange(event, index) {
			this.$set(this.panelsOpen, index, event.isOpen);
		},
	},
};
</script>

<style>
.c-navigation-header-dropdown-mobile__content {
	padding-top: 120px;
}

.t-navigation-dropdown-mobile-enter-active > *,
.t-navigation-dropdown-mobile-leave-active > * {
	@apply duration-500 ease-smooth-out transform;
}

.t-navigation-dropdown-mobile-enter
	.c-navigation-header-dropdown-mobile__backdrop,
.t-navigation-dropdown-mobile-leave-to
	.c-navigation-header-dropdown-mobile__backdrop {
	@apply opacity-0;
}

.t-navigation-dropdown-mobile-enter
	.c-navigation-header-dropdown-mobile__content,
.t-navigation-dropdown-mobile-leave-to
	.c-navigation-header-dropdown-mobile__content {
	@apply -translate-y-full;
}

.t-navigation-dropdown-mobile-item-enter-active,
.t-navigation-dropdown-mobile-item-leave-active {
	transition: max-height 300ms cubic-bezier(0.5, 0.035, 0.19, 1);
}

.t-navigation-dropdown-mobile-item-enter,
.t-navigation-dropdown-mobile-item-leave-to {
	max-height: 0;
}
</style>
