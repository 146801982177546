var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
		'c-navigation-header-dropdown-desktop',
		'fixed top-0 left-0 w-full h-screen' ],attrs:{"id":"navigation-dropdown","aria-label":"Navigationsmenu","role":"region"}},[_c('div',{class:[
			'c-navigation-header-dropdown-desktop__backdrop',
			'w-full h-full bg-primary-button' ],on:{"click":_vm.closeDropdown}}),_vm._v(" "),_c('div',{class:[
			'c-navigation-header-dropdown-desktop__content',
			'absolute top-0 left-0',
			'w-full bg-primary-medium',
			'pb-lg' ]},[_c('div',{staticClass:"w-full px-layout-margin max-w-layout-max mx-auto"},[_c('div',{ref:"contentContainer",class:[
					'c-navigation-header-dropdown-desktop__content-container',
					'relative duration-300 ease-smooth-out pl-2col pr-1col' ]},[_c('TransitionExt',{attrs:{"appear":"","name":"navigation-dropdown__inner","duration":300},on:{"enter":_vm.onContentEnter}},[_c('div',{key:_vm.item.id,ref:"content",class:[
							'c-navigation-header-dropdown-desktop__content-item',
							'grid grid-cols-3 gap-x-layout-gutter gap-y-md',
							'absolute px-layout-gutter' ]},_vm._l((_vm.item.children),function(child,index){return _c('div',{key:("dropdown-" + index)},[_c('NuxtLinkExt',{staticClass:"inline-flex flex-col",attrs:{"to":child.url,"target":child.target}},[_c('div',{staticClass:"\n\t\t\t\t\t\t\t\t\t\ttext-h3\n\t\t\t\t\t\t\t\t\t\tfont-semibold\n\t\t\t\t\t\t\t\t\t\tinline-flex\n\t\t\t\t\t\t\t\t\t\titems-baseline\n\t\t\t\t\t\t\t\t\t"},[_c('div',{staticClass:"\n\t\t\t\t\t\t\t\t\t\t\tinline-flex\n\t\t\t\t\t\t\t\t\t\t\tjustify-center\n\t\t\t\t\t\t\t\t\t\t\titems-center\n\t\t\t\t\t\t\t\t\t\t\tflex-shrink-0\n\t\t\t\t\t\t\t\t\t\t\t-ml-8\n\t\t\t\t\t\t\t\t\t\t\tpl-8\n\t\t\t\t\t\t\t\t\t\t\tw-md\n\t\t\t\t\t\t\t\t\t\t\tmr-sm\n\t\t\t\t\t\t\t\t\t\t",staticStyle:{"height":"0"}},[(child.icon)?_c('PageIcon',{staticClass:"\n\t\t\t\t\t\t\t\t\t\t\t\tw-full\n\t\t\t\t\t\t\t\t\t\t\t\ttransform\n\t\t\t\t\t\t\t\t\t\t\t\ttranslate-y-1/4\n\t\t\t\t\t\t\t\t\t\t\t",staticStyle:{"aspect-ratio":"1"},attrs:{"name":child.icon}}):_vm._e()],1),_vm._v(" "),_vm._v("\n\t\t\t\t\t\t\t\t\t"+_vm._s(child.title)+"\n\t\t\t\t\t\t\t\t")]),_vm._v(" "),(child.teaser)?_c('div',{staticClass:"inline-flex flex-shrink-0 mt-10"},[_c('div',{staticClass:"\n\t\t\t\t\t\t\t\t\t\t\tflex-shrink-0\n\t\t\t\t\t\t\t\t\t\t\t-ml-8\n\t\t\t\t\t\t\t\t\t\t\tpl-8\n\t\t\t\t\t\t\t\t\t\t\tw-md\n\t\t\t\t\t\t\t\t\t\t\tmr-sm\n\t\t\t\t\t\t\t\t\t\t"})]):_vm._e()])],1)}),0)])],1)]),_vm._v(" "),_c('BaseButton',{staticClass:"mt-lg mx-auto",domProps:{"textContent":_vm._s('Luk')},on:{"click":_vm.closeDropdown},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('SvgCross')]},proxy:true},{key:"default",fn:function(){return [_vm._v("Luk")]},proxy:true}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }