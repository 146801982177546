var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
		'c-navigation-header-dropdown-mobile',
		'fixed top-0 left-0 w-full h-screen' ],attrs:{"id":"navigation-dropdown","aria-label":"Navigationsmenu","role":"region"}},[_c('div',{class:[
			'c-navigation-header-dropdown-mobile__backdrop',
			'w-full h-full bg-primary-button' ],on:{"click":_vm.closeDropdown}}),_vm._v(" "),_c('div',{class:[
			'c-navigation-header-dropdown-mobile__content',
			'absolute top-0 left-0',
			'w-full max-h-visual-screen overflow-y-auto',
			'px-layout-margin pb-lg bg-primary-medium w-full' ]},[_c('AccordionGroup',{attrs:{"max-one-open":true}},_vm._l((_vm.items),function(item,index){return _c('div',{key:("item-" + index),staticClass:"border-b border-primary-light"},[(!item.isMegaMenu)?_c('NuxtLinkExt',{staticClass:"block w-full py-sm uppercase",attrs:{"to":item.url,"target":item.target}},[_c('div',{staticClass:"text-h5 font-semibold",domProps:{"textContent":_vm._s(item.title)}}),_vm._v(" "),_c('div',{staticClass:"text-h6 font-normal",domProps:{"textContent":_vm._s(item.teaser)}})]):_vm._e(),_vm._v(" "),(item.isMegaMenu)?_c('AccordionHeader',{key:("header-" + _vm._uid + "-" + index),staticClass:"w-full py-sm flex justify-between",attrs:{"id":("header-" + _vm._uid + "-" + index),"aria-controls":("panel-" + _vm._uid + "-" + index)}},[_c('div',{staticClass:"uppercase"},[_c('div',{staticClass:"text-h5 font-semibold",domProps:{"textContent":_vm._s(item.title)}}),_vm._v(" "),_c('div',{staticClass:"text-h6 font-normal",domProps:{"textContent":_vm._s(item.teaser)}})]),_vm._v(" "),_c('SvgCaret',{class:[
							'flex-shrink-0 text-text w-10',
							'transform duration-300 ease-smooth-out',

							{
								'rotate-90': !_vm.panelsOpen[index],
								'-rotate-90': _vm.panelsOpen[index],
							} ]})],1):_vm._e(),_vm._v(" "),(item.isMegaMenu)?_c('AccordionPanel',{key:("panel-" + _vm._uid + "-" + index),attrs:{"id":("panel-" + _vm._uid + "-" + index),"transition":{
						name: 't-navigation-dropdown-mobile-item',
					}},on:{"change":function (event) { return _vm.onPanelChange(event, index); }}},[_c('div',{staticClass:"px-sm pb-md pt-8 space-y-sm"},_vm._l((item.children),function(child,cindex){return _c('NuxtLinkExt',{key:("child-" + cindex),staticClass:"flex items-center",attrs:{"to":child.url,"target":child.target}},[(child.icon)?_c('div',{staticClass:"flex-shrink-0 w-md mr-sm"},[_c('PageIcon',{staticClass:"w-md text-text",attrs:{"name":child.icon}})],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"flex-1 text-h3 font-semibold",domProps:{"textContent":_vm._s(child.title)}})])}),1)]):_vm._e()],1)}),0),_vm._v(" "),(_vm.supportPage)?_c('NuxtLinkExt',{staticClass:"block w-fit mx-auto mt-xl",attrs:{"target":_vm.supportPage.target,"to":_vm.supportPage.url}},[_c('BaseButton',{attrs:{"tag":"div"},domProps:{"textContent":_vm._s('Kontakt')}})],1):_vm._e(),_vm._v(" "),(_vm.contact)?_c('NuxtLinkExt',{class:[
				'w-fit h-40 rounded-full bg-primary-button text-white mx-auto mt-xl',
				'flex justify-center items-center px-16 uppercase font-semibold' ],attrs:{"target":"_self","to":_vm.contact.url,"aria-label":_vm.contact.name}},[_vm._v("\n\t\t\t"+_vm._s(_vm.contact.name)+"\n\t\t")]):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }