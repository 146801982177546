// Last modified: 2022/02/22 09:41:26
import Vue from 'vue';

import AccordionGroup from './AccordionGroup.vue';
import AccordionHeader from './AccordionHeader.vue';
import AccordionPanel from './AccordionPanel.vue';

const _accordionMaps = Vue.observable({
	headers: {},
	panels: {},
});

export { AccordionGroup, AccordionHeader, AccordionPanel, _accordionMaps };
